import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { Web3ContextType } from "@web3-react/core";
import { UseFormReturn } from "react-hook-form";
import { BigNumber } from "ethers";

import { FormWrapper } from "@dzambalaorg/mui-form";
import { useApi } from "@dzambalaorg/provider-api-firebase";
import { PageHeader } from "@dzambalaorg/mui-page-layout";
import { useMetamask, useSystemContract, useBalance, useAllowance } from "@dzambalaorg/react-hooks-eth";
import { ContractType, IAssetComponent, IContract, IVestingBox } from "@framework/types";
import { cleanUpAsset, convertDatabaseAssetToTokenTypeAsset } from "@framework/exchange";

import { emptyValues, validationSchema, VestingBoxForm } from "../../../../../components/forms";
import { Breadcrumbs } from "../../../../../components/breadcrumbs";
import { CONTENT_PLATFORM_FEE } from "../../../../../shared";

const setContentPlatformFee = (contentAmount?: string) => {
  const numericAmount = Number(contentAmount);
  if (!contentAmount || Number.isNaN(numericAmount)) {
    return 1e18;
  }
  const contentPlatformFee = (numericAmount * CONTENT_PLATFORM_FEE) / 100;
  return numericAmount + contentPlatformFee;
};

export const CreateVestingBox = () => {
  const navigate = useNavigate();
  const api = useApi();
  const metaFnWithAllowance = useAllowance(
    async (_web3Context: Web3ContextType, values: IVestingBox, form: UseFormReturn<IVestingBox>) => {
      const {
        content,
        template,
        period,
        growthRate,
        cliff,
        afterCliffBasisPoints,
        imageUrl,
        backgroundImg,
        title,
        description,
        shape,
        duration,
        wallet,
        vestingBoxCat,
      } = values;

      const preparedValues = {
        title,
        description,
        imageUrl,
        shape,
        duration,
        growthRate,
        wallet,
        vestingBoxCat,
        backgroundImg: backgroundImg || null,
        content: cleanUpAsset(content),
        price: cleanUpAsset(template?.price),
        contractId: template?.contractId,
        period: period || 1,
        cliff: cliff || 0,
        afterCliffBasisPoints: afterCliffBasisPoints || 0,
        cap: template?.cap.toString() || "0",
      };

      return await api
        .fetchJson({
          url: "/vesting/boxes",
          method: "POST",
          data: preparedValues,
        })
        .then(() => {
          form.reset();
          navigate(-1);
          return null;
        });
    },
    { hasApproveTx: false },
  );

  const metaFnWithBalance = useBalance(
    async (
      web3Context: Web3ContextType,
      values: IVestingBox,
      form: UseFormReturn<IVestingBox>,
      systemContract: IContract,
    ) => {
      const assets = convertDatabaseAssetToTokenTypeAsset(
        values.content?.components as unknown as Array<IAssetComponent>,
      ).map(asset => {
        return { ...asset, amount: String(setContentPlatformFee(asset.amount)) };
      });

      const numericCap = Number(values.template?.cap);
      const cap = numericCap !== 0 ? numericCap : 1;

      for (let i = 0; i < cap - 1; i++) {
        const withFeeContentAmount = setContentPlatformFee(assets[0].amount);
        assets.push({ ...assets[0], amount: String(withFeeContentAmount) });
      }

      return metaFnWithAllowance(
        {
          contract: values.content?.components[0].contract?.address!,
          assets,
          allowance: { ownerAddress: values.wallet, senderAddress: systemContract.address },
        },
        web3Context,
        values,
        form,
      ) as Promise<void>;
    },
  );

  const metaFnWithSystemContract = useSystemContract(
    async (
      values: IVestingBox,
      web3Context: Web3ContextType,
      form: UseFormReturn<IVestingBox>,
      systemContract: IContract,
    ) => {
      const numericCap = Number(values.template?.cap);

      const processAsset = () => {
        const withFeeContentAmount = setContentPlatformFee(values.content?.components[0].amount);
        return numericCap > 0 ? withFeeContentAmount * Number(values.template?.cap) : withFeeContentAmount;
      };
      const amount = processAsset();
      return metaFnWithBalance(
        {
          wallet: values.wallet,
          contract: values.content?.components[0].contract?.address!,
          amount: BigNumber.from(String(amount)),
        },
        web3Context,
        values,
        form,
        systemContract,
      ) as Promise<void>;
    },
  );

  const metaFn = useMetamask((values: IVestingBox, form: any, web3Context: Web3ContextType) => {
    return metaFnWithSystemContract(ContractType.EXCHANGE, values, web3Context, form);
  });

  const handleConfirm = async (values: IVestingBox, form: any) => {
    return metaFn(values, form);
  };

  return (
    <Grid>
      <Breadcrumbs path={["dashboard", "vesting", "vesting.boxes", "vesting.create"]} />
      <PageHeader message="pages.vesting.create.title" />
      <FormWrapper initialValues={emptyValues} onSubmit={handleConfirm} validationSchema={validationSchema}>
        <VestingBoxForm />
      </FormWrapper>
    </Grid>
  );
};
