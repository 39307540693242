export enum Seconds {
  TEN_MINUTES = "TEN_MINUTES",
  THIRTY_MINUTES = "THIRTY_MINUTES",
  ONE_HOUR = "ONE_HOUR",
  THREE_HOURS = "THREE_HOURS",
  SIX_HOURS = "SIX_HOURS",
  TWELVE_HOURS = "TWELVE_HOURS",
  ONE_DAY = "ONE_DAY",
  TWO_DAYS = "TWO_DAYS",
  SEVEN_DAYS = "SEVEN_DAYS",
  MONTH = "MONTH",
  THREE_MONTH = "THREE_MONTH",
  SIX_MONTH = "SIX_MONTH",
  YEAR = "YEAR",
  TWO_YEARS = "TWO_YEARS",
}

export type LessThanDaySecondsExclusions =
  | Seconds.TEN_MINUTES
  | Seconds.THIRTY_MINUTES
  | Seconds.ONE_HOUR
  | Seconds.THREE_HOURS
  | Seconds.SIX_HOURS
  | Seconds.TWELVE_HOURS;

export type MoreThanDaySecondsExclusions =
  | Seconds.ONE_DAY
  | Seconds.TWO_DAYS
  | Seconds.SEVEN_DAYS
  | Seconds.MONTH
  | Seconds.THREE_MONTH
  | Seconds.SIX_MONTH
  | Seconds.YEAR
  | Seconds.TWO_YEARS;
