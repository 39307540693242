import { useMemo, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { IVestingBox } from "@framework/types";

import { SelectInput } from "../../../inputs/select";
import { periodOptions, stepsShapes, secondsValues } from "../constants";
import { optionsFilterByDuration } from "../utils";
import { Seconds } from "../../../../shared";

export const PeriodDropdown = () => {
  const form = useFormContext<IVestingBox>();
  const shape = form.watch("shape");
  const duration = form.watch("duration");

  const isPeriod = stepsShapes.includes(shape);

  const filteredPeriodOptions = useMemo(() => {
    const period = form.getValues("period");
    return optionsFilterByDuration(period, duration, form, "period", periodOptions);
  }, [duration]);

  const disabledOptions = stepsShapes.includes(shape)
    ? periodOptions.filter(option => option.value !== secondsValues[Seconds.MONTH]).map(option => option.value)
    : [];

  useEffect(() => {
    const period = form.getValues("period");

    if (!isPeriod && !!period) {
      form.unregister("period", { keepDirty: false, keepValue: false });
    }

    if (isPeriod) {
      form.setValue("period", secondsValues[Seconds.MONTH]);
    }
  }, [isPeriod]);

  if (!shape || !isPeriod) {
    return null;
  }

  return <SelectInput name="period" options={filteredPeriodOptions} disabledOptions={disabledOptions} required />;
};
