import { useFormContext } from "react-hook-form";

import { IVestingBox } from "@framework/types";

import { SelectInput } from "../../../inputs/select";
import { durationOptions, stepsShapes, secondsValues, devSecondsValues } from "../constants";
import { Seconds } from "../../../../shared";

export const DurationDropdown = () => {
  const form = useFormContext<IVestingBox>();
  const shape = form.watch("shape");

  const disabledOptions = stepsShapes.includes(shape)
    ? [
        secondsValues[Seconds.MONTH],
        devSecondsValues[Seconds.ONE_HOUR],
        devSecondsValues[Seconds.THREE_HOURS],
        devSecondsValues[Seconds.SIX_HOURS],
        devSecondsValues[Seconds.TWELVE_HOURS],
      ]
    : [];

  return <SelectInput name="duration" options={durationOptions} disabledOptions={disabledOptions} required />;
};
