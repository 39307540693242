import { VestingType } from "@framework/types";

import { LessThanDaySecondsExclusions, MoreThanDaySecondsExclusions, Seconds } from "../../../../shared";

export const devSecondsValues: Record<Exclude<Seconds, MoreThanDaySecondsExclusions>, number> = {
  [Seconds.TEN_MINUTES]: 600,
  [Seconds.THIRTY_MINUTES]: 1800,
  [Seconds.ONE_HOUR]: 3600,
  [Seconds.THREE_HOURS]: 10800,
  [Seconds.SIX_HOURS]: 21600,
  [Seconds.TWELVE_HOURS]: 43200,
};

export const secondsValues: Record<Exclude<Seconds, LessThanDaySecondsExclusions>, number> = {
  [Seconds.ONE_DAY]: 86400,
  [Seconds.TWO_DAYS]: 172800,
  [Seconds.SEVEN_DAYS]: 604800,
  [Seconds.MONTH]: 2592000,
  [Seconds.THREE_MONTH]: 7776000,
  [Seconds.SIX_MONTH]: 15552000,
  [Seconds.YEAR]: 31104000,
  [Seconds.TWO_YEARS]: 62208000,
};

export const vestingFunctionPeriods: Record<Exclude<VestingType, VestingType.LINEAR>, Record<number, number>> = {
  [VestingType.HYPERBOLIC]: {
    [secondsValues[Seconds.MONTH]]: secondsValues[Seconds.ONE_DAY] / 2,
    [secondsValues[Seconds.THREE_MONTH]]: (secondsValues[Seconds.ONE_DAY] * 3) / 2,
    [secondsValues[Seconds.SIX_MONTH]]: (secondsValues[Seconds.ONE_DAY] * 6) / 2,
    [secondsValues[Seconds.YEAR]]: (secondsValues[Seconds.ONE_DAY] * 12) / 2,
    [secondsValues[Seconds.TWO_YEARS]]: (secondsValues[Seconds.ONE_DAY] * 24) / 2,
  },
  [VestingType.EXPONENTIAL]: {
    [secondsValues[Seconds.MONTH]]: secondsValues[Seconds.ONE_DAY],
    [secondsValues[Seconds.THREE_MONTH]]: secondsValues[Seconds.ONE_DAY] * 3, // 3 days
    [secondsValues[Seconds.SIX_MONTH]]: secondsValues[Seconds.ONE_DAY] * 6, // 6 days
    [secondsValues[Seconds.YEAR]]: secondsValues[Seconds.ONE_DAY] * 12, // 12 days
    [secondsValues[Seconds.TWO_YEARS]]: secondsValues[Seconds.ONE_DAY] * 24, // 24 days
  },
};
