import { type ITemplate, ShapeType, TokenType } from "@framework/types";
import { emptyStateString } from "@dzambalaorg/draft-js-utils";
import { emptyPrice, getEmptyTemplate } from "@dzambalaorg/mui-inputs-asset";
import { defaultValues, secondsValues } from "./constants";
import { Seconds } from "../../../shared";

export const emptyValues = {
  title: "",
  description: emptyStateString,
  content: getEmptyTemplate(TokenType.ERC20),
  backgroundImg: "",
  template: {
    price: emptyPrice,
    cap: "0",
  } as ITemplate,
  duration: secondsValues[Seconds.YEAR],
  shape: ShapeType.LINEAR,
  period: 1,
  ...defaultValues,
};
