import { useFormContext } from "react-hook-form";

import { useDidMountEffect } from "@dzambalaorg/react-hooks";
import { IVestingBox, ShapeType, VestingType } from "@framework/types";

import { Seconds } from "../../../../shared";
import { SelectInput } from "../../../inputs/select";
import {
  afterCliffBasisPointsShapes,
  devSecondsValues,
  growthRateShapes,
  nonLinearAndMonthlyShapes,
  secondsValues,
  vestingFunctionPeriods,
} from "../constants";

const setPeriod = (functionType: VestingType, duration: number, value: ShapeType, form: any): void => {
  if (!nonLinearAndMonthlyShapes.includes(value)) {
    return;
  }

  if (duration < secondsValues[Seconds.ONE_DAY]) {
    form.setValue("period", devSecondsValues[Seconds.TEN_MINUTES]);
  } else {
    form.setValue("period", vestingFunctionPeriods[functionType as keyof typeof vestingFunctionPeriods][duration]);
  }
};

export const ShapeDropdown = () => {
  const form = useFormContext<IVestingBox>();
  const duration = form.watch("duration");
  const shape = form.watch("shape");

  const onChange = (e: any) => {
    const value = e.target.value as ShapeType;
    const functionType = value.split("_")[0] as VestingType;

    if (growthRateShapes.includes(value)) {
      // @ts-ignore
      form.setValue("growthRate", 200, { shouldTouch: true, shouldDirty: true });
    }

    if (afterCliffBasisPointsShapes.includes(value)) {
      form.setValue("afterCliffBasisPoints", 2500, { shouldTouch: true, shouldDirty: true });
    }

    setPeriod(functionType, duration, value, form);

    form.setValue("shape", value, { shouldTouch: true, shouldDirty: true });
  };

  useDidMountEffect(() => {
    const functionType = shape.split("_")[0] as VestingType;
    setPeriod(functionType, duration, shape, form);
  }, [duration]);

  return <SelectInput name="shape" options={ShapeType} onChange={onChange} required />;
};
