import { FunctionPlotDatum } from "function-plot";

import { ShapeType } from "@framework/types";

import { Seconds } from "../../../../shared";
import { TOption } from "../../../inputs/select";
import { TPlotConfig } from "../types";
import { generateExponentialSteppedData, generateHyperbolicSteppedData, generateLinearSteppedData } from "../utils";
import { devSecondsValues, secondsValues, vestingFunctionPeriods } from "./times";

export { devSecondsValues, secondsValues, vestingFunctionPeriods };

export const defaultValues = {
  cliff: 0,
  growthRate: 100,
  afterCliffBasisPoints: 0,
  amount: 0,
};

export const cliffExclusions = [Seconds.TWO_YEARS];
export const cliffOptions: Array<TOption<number>> = Object.entries(
  process.env.NODE_ENV !== "development" ? secondsValues : { ...devSecondsValues, ...secondsValues },
)
  .filter(([key, _]) => !cliffExclusions.includes(key as Seconds))
  .map(([key, value]) => ({
    title: key,
    value: value,
  }));

export const periodExclusions = [Seconds.TWO_DAYS, Seconds.YEAR, Seconds.TWO_YEARS];
export const periodOptions: Array<TOption<number>> = Object.entries(secondsValues)
  .filter(([key, _]) => !periodExclusions.includes(key as Seconds))
  .map(([key, value]) => ({
    title: key,
    value: value,
  }));

export const baseDurationExclusions = [Seconds.ONE_DAY, Seconds.TWO_DAYS, Seconds.SEVEN_DAYS];
export const devDurationExclusions = [];
export const durationExclusions =
  process.env.NODE_ENV !== "development"
    ? baseDurationExclusions
    : baseDurationExclusions.concat(devDurationExclusions);
export const durationOptions: Array<TOption<number>> = Object.entries(
  process.env.NODE_ENV !== "development" ? secondsValues : { ...devSecondsValues, ...secondsValues },
)
  .filter(([key, _]) => !durationExclusions.includes(key as Seconds))
  .map(([key, value]) => ({
    title: key,
    value: value,
  }));

export const plotDataByShape: Record<ShapeType, (config: TPlotConfig) => Array<FunctionPlotDatum>> = {
  [ShapeType.LINEAR]: ({ duration }) => [{ fn: `x / ${duration} * 100`, color: "#65a3ff" }],
  [ShapeType.LINEAR_CLIFF]: ({ duration, cliff }) => [
    { fn: "0", range: [0, cliff], color: "#65a3ff" },
    { fn: `(x - ${cliff}) / (${duration} - ${cliff}) * 100`, range: [cliff, duration], color: "#65a3ff" },
  ],
  [ShapeType.LINEAR_IMMEDIATE]: ({ duration, immediateUnlockPercentage, immediateUnlockPercentageRestPercent }) => [
    {
      fn: `${immediateUnlockPercentage} + (x / ${duration}) * ${immediateUnlockPercentageRestPercent}`,
      color: "#65a3ff",
    },
  ],
  [ShapeType.LINEAR_CLIFF_IMMEDIATE]: ({
    duration,
    cliff,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
  }) => [
    { fn: "0", range: [0, cliff], color: "#65a3ff" },
    {
      fn: `(x - ${cliff}) / (${duration} - ${cliff}) * ${immediateUnlockPercentageRestPercent} + ${immediateUnlockPercentage}`,
      range: [cliff, duration],
      color: "#65a3ff",
    },
    {
      points: [
        [cliff, 0],
        [cliff, immediateUnlockPercentage],
      ],
      fnType: "points",
      graphType: "polyline",
      color: "#65a3ff",
    },
  ],
  [ShapeType.EXPONENTIAL]: ({ duration, growthRate, period }) => [
    {
      fn: `(${growthRate}^(x/${period} - ${duration / period})) * 100 * (x / ${duration})`,
      color: "#65a3ff",
      graphType: "polyline",
    },
  ],
  [ShapeType.EXPONENTIAL_CLIFF]: ({ duration, cliff, growthRate, period }) => [
    { fn: "0", range: [0, cliff], color: "#65a3ff", graphType: "polyline" },
    {
      fn: `(${growthRate}^((x - ${cliff})/${period} - ${(duration - cliff) / period})) * 100 * ((x - ${cliff}) / ${duration - cliff})`,
      range: [cliff, duration],
      color: "#65a3ff",
      graphType: "polyline",
    },
  ],
  [ShapeType.EXPONENTIAL_IMMEDIATE]: ({
    duration,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    growthRate,
    period,
  }) => [
    {
      fn: `${immediateUnlockPercentage} + (${growthRate}^(x/${period} - ${duration / period})) * ${immediateUnlockPercentageRestPercent} * (x / ${duration})`,
      color: "#65a3ff",
      graphType: "polyline",
    },
  ],
  [ShapeType.EXPONENTIAL_CLIFF_IMMEDIATE]: ({
    duration,
    cliff,
    growthRate,
    immediateUnlockPercentage,
    immediateUnlockPercentageRestPercent,
    period,
  }) => [
    { fn: "0", range: [0, cliff], color: "#65a3ff" },
    {
      fn: `${immediateUnlockPercentage} + (${growthRate}^((x - ${cliff})/${period} - ${(duration - cliff) / period})) * ${immediateUnlockPercentageRestPercent} * ((x - ${cliff}) / ${duration - cliff})`,
      range: [cliff, duration],
      color: "#65a3ff",
      graphType: "polyline",
    },
    {
      points: [
        [cliff, 0],
        [cliff, immediateUnlockPercentage],
      ],
      fnType: "points",
      graphType: "polyline",
      color: "#65a3ff",
    },
  ],
  [ShapeType.HYPERBOLIC]: ({ period }) => [
    { fn: `100 * (x / ${period}) / ((x / ${period}) + 1)`, color: "#65a3ff", graphType: "polyline" },
  ],
  [ShapeType.HYPERBOLIC_CLIFF]: ({ duration, cliff, period }) => [
    { fn: "0", range: [0, cliff], color: "#65a3ff", graphType: "polyline" },
    {
      fn: `100 * ((x - ${cliff}) / ${period}) / (((x - ${cliff}) / ${period}) + 1)`,
      range: [cliff, duration],
      color: "#65a3ff",
      graphType: "polyline",
    },
  ],
  [ShapeType.HYPERBOLIC_IMMEDIATE]: ({ immediateUnlockPercentageRestPercent, period }) => [
    {
      fn: `${immediateUnlockPercentageRestPercent} * (x / ${period}) / ((x / ${period}) + 1)`,
      color: "#65a3ff",
      graphType: "polyline",
    },
  ],
  [ShapeType.HYPERBOLIC_CLIFF_IMMEDIATE]: ({ duration, cliff, immediateUnlockPercentageRestPercent, period }) => [
    { fn: "0", range: [0, cliff], color: "#65a3ff", graphType: "polyline" },
    {
      fn: `${immediateUnlockPercentageRestPercent} * ((x - ${cliff}) / ${period}) / (((x - ${cliff}) / ${period}) + 1)`,
      range: [cliff, duration],
      color: "#65a3ff",
      graphType: "polyline",
    },
    {
      points: [
        [cliff, 0],
        [cliff, 0],
      ],
      fnType: "points",
      graphType: "polyline",
      color: "#65a3ff",
    },
  ],
  [ShapeType.LINEAR_MONTHLY_STEPS]: ({ duration, period }) =>
    generateLinearSteppedData({ duration, period }).map(step => ({
      fn: step.fn,
      range: step.range,
      closed: true,
      color: "#65a3ff",
    })) as FunctionPlotDatum[],
  [ShapeType.EXPONENTIAL_MONTHLY_STEPS]: ({ duration, period, growthRate }) =>
    generateExponentialSteppedData({ duration, period, growthRate }).map(step => ({
      fn: step.fn,
      range: step.range,
      closed: true,
      color: "#65a3ff",
    })) as FunctionPlotDatum[],
  [ShapeType.HYPERBOLIC_MONTHLY_STEPS]: ({ duration, period }) =>
    generateHyperbolicSteppedData({ duration, period }).map(step => ({
      fn: step.fn,
      range: step.range,
      closed: true,
      color: "#65a3ff",
    })) as FunctionPlotDatum[],
};

export const nonLinearAndMonthlyShapes = Object.values(ShapeType).filter(
  shape => !shape.includes("LINEAR") && !shape.includes("MONTHLY"),
);

export const growthRateShapes = [
  ShapeType.EXPONENTIAL_CLIFF,
  ShapeType.EXPONENTIAL,
  ShapeType.EXPONENTIAL_IMMEDIATE,
  ShapeType.EXPONENTIAL_CLIFF_IMMEDIATE,
  ShapeType.EXPONENTIAL_MONTHLY_STEPS,
];

export const cliffShapes = [
  ShapeType.LINEAR_CLIFF,
  ShapeType.LINEAR_CLIFF_IMMEDIATE,
  ShapeType.EXPONENTIAL_CLIFF,
  ShapeType.EXPONENTIAL_CLIFF_IMMEDIATE,
  ShapeType.HYPERBOLIC_CLIFF,
  ShapeType.HYPERBOLIC_CLIFF_IMMEDIATE,
];

export const immediateShapes = [
  ShapeType.LINEAR_IMMEDIATE,
  ShapeType.EXPONENTIAL_IMMEDIATE,
  ShapeType.HYPERBOLIC_IMMEDIATE,
];
export const afterCliffBasisPointsShapes = immediateShapes.concat([
  ShapeType.LINEAR_CLIFF_IMMEDIATE,
  ShapeType.EXPONENTIAL_CLIFF_IMMEDIATE,
  ShapeType.HYPERBOLIC_CLIFF_IMMEDIATE,
]);

export const stepsShapes = [
  ShapeType.LINEAR_MONTHLY_STEPS,
  ShapeType.EXPONENTIAL_MONTHLY_STEPS,
  ShapeType.HYPERBOLIC_MONTHLY_STEPS,
];
